body {
  background-color: #F2F2F2
}

.App {
  text-align: center;
  background-color: #F2F2F2
}

.today-button-label {
  transition: 0.2s color;
}

.today-button:hover .today-button-label {
  color: #40a9ff!important
}

.timeline-event {
  cursor: ew-resize!important;
}
